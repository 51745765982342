import DownloadApp from "components/cta/DownloadApp";
import FAQ from "components/faqs/SingleCol.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionDescription } from "components/misc/Typography.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ShieldIconImage from "images/shield-icon.svg";
import tw from "twin.macro";

import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const roadmap = [
    {
      title: "Navigasjon",
      description: "Legge inn navigasjonsmuligheter i appen",
    },
    {
      title: "Fotobokser",
      description: "Varsel for fotobokser og snittmåling på kartet",
    },
    {
      title: "Bedre prikker",
      description:
        "Legge inn antall prikker du allerede har på førerkortet inn i beregningen",
    },
    {
      title: "Android App",
      description: "Legge ut appen for Android",
    },

    {
      title: "Drivstoffpriser",
      description: "Legge inn drivstoffpriser i appen",
    },
    {
      title: "Politikontroller",
      description: "Legge inn politikontroller i appen",
    },
    {
      title: "Carplay",
      description: "Lage støtte for Carplay",
    },
  ];
  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Funksjoner</Subheading>}
        cards={[
          {
            imageSrc: ShieldIconImage,
            title: "Bøter",
            description: "Se hvor mye du risikerer å få i bot",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Prikker",
            description: "Se hvor mange prikker du risikerer å få",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Fartsgrense",
            description: "Se hva fartsgrensen er der du kjører",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Forkjørsvei?",
            description:
              "Lurer du på om veien du kjører på er forkjørsvei? Appen viser deg!",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Førerkortbeslag",
            description:
              "Appen gir beskjed hvis du kjører så fort at du risikerer førerkortbeslag",
          },
          {
            imageSrc: "",
            title: null,
            description: "",
          },
        ]}
        heading={
          <>
            Her er noen av våre beste{" "}
            <HighlightedText>funksjoner.</HighlightedText>
          </>
        }
      />
      <DownloadApp
        subheading="Last ned appen"
        text="Last ned appen i dag"
        link1Text="App Store"
      />

      {/*<MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our{" "}
            <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description:
              "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description:
              "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      />*/}
      <div id="priser">
        <Pricing
          subheading={<Subheading>Pricing</Subheading>}
          primaryButtonText="Last Ned"
          description=""
          heading={
            <>
              Se våre <HighlightedText>priser.</HighlightedText>
            </>
          }
          plans={[
            {
              name: "Gratis",
              durationPrices: ["0,-", "0,-"],
              mainFeature: "Helt gratis :)",
              features: ["All tilgang"],
            },
          ]}
        />
      </div>
      {/*<Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc.",
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC",
          },
        ]}
      />*/}
      <FAQ
        subheading={<Subheading>FAQ</Subheading>}
        heading={
          <>
            Spørsmål og <HighlightedText>Svar</HighlightedText>
          </>
        }
        description=""
        faqs={[
          {
            question: "Lagrer appen hvor fort jeg kjører?",
            answer:
              "Nei, appen lagrer aldri data angående lokasjon eller fart.",
          },
          {
            question: "Hvor henter dere data fra?",
            answer:
              "Vi henter bøtesatser fra lovdata.no, og veidata fra Statens Vegvesen. Kartet i appen er IOS native map (Apple Kart)",
          },
          {
            question: "Kommer appen i Google Play?",
            answer:
              "Planen er å lage appen til Android, men vi er fortsatt i et tidlig stadie av app utviklingen. Dagens app er kun laget til IOS og vi vil kartlege behov og funksjoner før vi også lager en Android App",
          },
        ]}
      />
      {/*<GetStarted />*/}

      <Container>
        <ContentWithPaddingXl>
          <Column>
            <HeaderContent>
              <Heading>Roadmap</Heading>
              <Description>Her er våre planer videre med appen</Description>
            </HeaderContent>
          </Column>
          <br></br>
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
              marginLeft: "15%",
            }}
          >
            {roadmap.map((data, index) => {
              return (
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">
                    {data.title}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    {index + 1 != roadmap.length && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>{data.description}</TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
          <p style={{ textAlign: "center" }}>
            Vi tar i mot forslag på funksjoner. Send oss en mail:{" "}
            <a href="mailto:trafikkbevist@sandenutvikling.no">
              trafikkbevist@sandenutvikling.no
            </a>
          </p>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
