import Footer from "components/footers/FiveColumnWithBackground";
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import styled from "styled-components";
import tw from "twin.macro";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Personvernerklæring" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Sist oppdatert: 29 Mai, 2023</p>

            <h1>1 - Introduksjon</h1>

            <p>
              Sanden Utvikling ENK ("vi") er opptatt av at du skal ha tillit til
              oss og hvordan vi behandler dine personopplysninger. I denne
              personvernerklæringen forklarer vi derfor hvorfor vi samler inn
              informasjon om deg, hvordan vi bruker denne informasjonen og
              hvordan vi tar hensyn til ditt personvern.
            </p>
            <p>
              Personopplysninger er opplysninger og vurderinger som kan knyttes
              til en identifiserbar enkeltperson. Dette kan for eksempel være
              navn og kontaktinformasjon, kjøpshistorikk eller informasjon om
              interesser.
            </p>

            <p>
              Bruk av personopplysninger må følge personopplysningsloven. Vi,
              som behandlingsansvarlig, vil sørge for at vi bruker
              personopplysninger om deg i samsvar med lovens regler. Behandling
              av personlige data er nødvendig for at vi skal kunne betjene deg
              som kunde.
            </p>
            <p>
              Ved å gi oss dine personlige opplysninger, godtar du praksis og
              vilkår som er beskrevet i denne personvernerklæringen. Om du har
              innsigelser med hensyn til vår behandling av personvern har du
              også anledning å klage til Datatilsynet.
            </p>

            <h1>2 - Behandlingsansvarlig</h1>
            <p>Sanden Utvikling ENK</p>
            <p>Vegamot 4A</p>
            <p>7048 Trondheim</p>
            <p>E-post: trafikkbevist@sandenutvikling.no</p>
            <p>Telefon: 930 99 340</p>
            <p>Org nr: 930 727 997</p>

            <h1>3 - Hvilke personopplysninger har vi om deg</h1>
            <p>
              Opplysninger kan registreres og lagres når du registrerer deg i
              appen, når du er i kontakt med salg og support enten via e-post,
              chat eller når du bruker våre tjenester.
            </p>
            <p>
              Avhengig av hvilken informasjon du gir oss, hvordan du bruker våre
              tjenester, og hvilke tillatelser du gir oss, har vi disse
              opplysningene om deg:
            </p>
            <ul>
              <li>
                <strong>Opplysninger om deg:</strong> Informasjon du oppgir når
                du oppretter en brukerkonto, som e-postadresse og hvilke varsler
                du ønsker.
              </li>
              <li>
                <strong>Kjøpsinformasjon:</strong> Informasjon vi får når du
                kjøper tjenester fra oss, som hva du har kjøpt, kortnummer og
                betalingsmetode (f.eks. Visa). Kjøpsinformasjonen tilknyttes din
                brukerkonto.
              </li>
              <li>
                <strong>Kommunikasjon:</strong> Informasjon du gir oss når du
                har kontakt med support, som eposter sendt mellom oss.
              </li>
              <li>
                <strong>Opplysninger om bruk i appen:</strong> Informasjon vi
                får når du bruker vår app, som hva slags enhet du har (f.eks.
                mobil eller PC), IP-adresse, hvilke sider du besøker og hvilke
                funksjoner du bruker der.
              </li>
            </ul>

            <h1>4 - Hva bruker vi personopplysningene til</h1>
            <p>Vi bruker personopplysningene dine til følgende formål:</p>
            <ul>
              <li>
                <strong>Tilby app:</strong> Vi bruker opplysninger for å
                tilpasse vår app til det tekniske utstyret du bruker. Vi kan
                også bruke opplysningene til å tilpasse innholdet på vår app til
                hva vi tror du er interessert i.
              </li>
              <li>
                <strong>Opprette brukerkonto:</strong> Vi bruker opplysninger
                dersom du oppretter brukerkonto, bl.a. for å gi deg tilgang med
                epost og passord, for å lagre din kontaktinformasjon og
                betalingsinformasjon.
              </li>
              <li>
                <strong>Support:</strong> Vi lagrer opplysninger som du gir og
                mottar dersom du har kontakt med oss for å yte service og
                administrere ditt kundeforhold.
              </li>
              <li>
                <strong>Statistikk:</strong> Vi utarbeider statistikk og
                kartlegger markedstrender for å forbedre og videreutvikle våre
                produkter og tjenester. Så langt det er mulig gjør vi dette med
                anonyme opplysninger, uten at vi vet at informasjonen er knyttet
                spesifikt til deg.
              </li>
            </ul>

            <h1>5 - Hvem deler vi personopplysninger med?</h1>
            <p>
              Vi deler personopplysninger med selskaper som leverer tekniske og
              administrative tjenester til oss, slik som IT-leverandører. Vi har
              avtaler med disse for å sikre ditt personvern.
            </p>
            <p>
              Vi kan utlevere opplysninger i enkelttilfeller, eksempelvis til
              offentlige myndigheter, ved mistanke om lovbrudd.
            </p>

            <h1>
              6 - Hvor lagrer vi informasjon om deg – og hvor deler vi
              informasjonen om deg med andre?
            </h1>
            <p>
              Vår inloggingsløsning og database er levert av Google Firestore og
              data lagres i deres skytjenester hos Google Cloud sitt datasenter
              i Frankfurt, Tyksland.
            </p>
            <p>Noen av underleverandørene vi bruker:</p>
            <ul>
              <li>
                <strong>Stripe:</strong> Når man betaler for tjenester, vil
                betalingsinformasjon lagres hos Stripe. Dette inkluderer kunde
                ID, e-postadressen, og kortinformasjon.
              </li>
              <li>
                <strong>Google Firebase:</strong> Google Firebase er stedet hvor
                alt av dataen lagres. Vår data er lagret på Google sine
                datasenter i Frankfurt, Tyskland. Firebase håndterer også
                brukerkonto systemet.
              </li>
              <li>
                <strong>Google Analytics:</strong> Google Analytics benyttes for
                analyse av trafikk. IP-adresser og besøksmønster lagres.
              </li>
            </ul>

            <h1>7 - Kommunikasjon gjennom e-post</h1>
            <p>
              Som en kunde vil du, motta e-post med informasjon om ditt
              kundeforhold. Eksempel er ved aktivering av brukerkontoen din og
              varsler som imøtekommer innstillingene du har valg. Dette er
              definert som berettiget interesse og det vil ikke bes om samtykke
              til.
            </p>

            <h1>8 - Oppbevaring og sletting</h1>
            <p>
              Vi lagrer opplysninger om deg, kun så lenge det er nødvendig for å
              oppnå formålet de ble innhentet for, eller dersom vi er pålagt å
              lagre opplysningene. Opplysningene vil slettes eller anonymiseres
              når de ikke lengre er nødvendige for å oppnå formålet.
            </p>

            <h1>9 - Innsyn og retting</h1>
            <p>
              Du har krav på innsyn i de opplysninger som er registrert om deg.
              Innsyn kan også fås ved å sende en henvendelse til
              trafikkbevist@sandenutvikling.no. Dersom de registrerte
              opplysninger ikke er riktige eller er ufullstendige, kan du kreve
              at opplysningene korrigeres i henhold til personopplysningsloven.
            </p>

            <h1>10 - Informasjon om bruk av informasjonskapsler</h1>
            <p>
              En informasjonskapsel (cookie) er en liten tekstfil som lagres på
              din datamaskin.
            </p>
            <p>
              Vi bruker cookies på våre nettsider for å sikre funksjonalitet på
              nettsiden, analysere nettrafikk og se hvilke områder på vårt
              nettsted du har besøkt.
            </p>
            <p>
              Når du besøker våre tjenester, setter vi både midlertidige og
              varige informasjonskapsler. En midlertidig informasjonskapsel
              slettes ofte automatisk når du lukker nettleseren din, mens varige
              kan ligge på din maskin i opptil ett år.
            </p>
            <p>
              Om du ønsker å lære mer om informasjonskapsler kan
              http://www.aboutcookies.org benyttes.
            </p>
            <p>
              I henhold til norsk lov har du automatisk samtykket i at vi lagrer
              informasjonskapsler på din datamaskin dersom du tillater dette
              gjennom innstillingene i din nettleser.
            </p>

            <h1>11 - Endringer i personvernerklæringen</h1>
            <p>
              Vi vil kunne oppdatere personvernerklæringen fra tid til annen. Du
              vil få beskjed om endringer av betydning. Du vil alltid finne
              siste versjon av vår personvernerklæring på nettsiden/appen.
            </p>

            <h1>12 - Kontaktinformasjon</h1>
            <p>
              Hvis du ønsker å komme i kontakt med oss, kan du benytte følgende
              kontaktinformasjon:
            </p>
            <p>Kontaktperson: Emre Sanden</p>
            <p>Bedrift: Sanden Utvikling ENK</p>
            <p>Adresse: Vegamot 4A, 7048 Trondheim</p>
            <p>E-post: trafikkbevist@sandenutvikling.no</p>
            <p>Telefon: 930 99 340</p>
            <p>Org nr: 930 727 997</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
